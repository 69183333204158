import { default as finance_45reportss1JL7sVqXBMeta } from "/opt/build/repo/pages/administration/finance-reports.vue?macro=true";
import { default as indexd5hSPD5qQMMeta } from "/opt/build/repo/pages/administration/index.vue?macro=true";
import { default as indexWWXwSx8VntMeta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/[bookingReference]/index.vue?macro=true";
import { default as indexklFP8AULJ9Meta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/create/[performanceId]/index.vue?macro=true";
import { default as indexiSnQDn5fdfMeta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/create/index.vue?macro=true";
import { default as indexB11GvVTpe6Meta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/index.vue?macro=true";
import { default as edit2wQQQjEUHcMeta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/edit.vue?macro=true";
import { default as indexYqO5G68QVjMeta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/index.vue?macro=true";
import { default as editF1dnKzkM0VMeta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/performances/[performanceId]/edit.vue?macro=true";
import { default as indexLROpfMIxobMeta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/performances/[performanceId]/index.vue?macro=true";
import { default as createXdUuiywZLLMeta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/performances/create.vue?macro=true";
import { default as permissionsekuQMETpLfMeta } from "/opt/build/repo/pages/administration/productions/[productionSlug]/permissions.vue?macro=true";
import { default as createVnnrEcPrzbMeta } from "/opt/build/repo/pages/administration/productions/create.vue?macro=true";
import { default as indexqewSNHXCfLMeta } from "/opt/build/repo/pages/administration/productions/index.vue?macro=true";
import { default as ticket_45lookupP1qHM106OvMeta } from "/opt/build/repo/pages/administration/tools/ticket-lookup.vue?macro=true";
import { default as administrationrIxZaV94DjMeta } from "/opt/build/repo/pages/administration.vue?macro=true";
import { default as indexNGGUdoQWmaMeta } from "/opt/build/repo/pages/box-office/[performanceId]/index.vue?macro=true";
import { default as scanctRIp4xkHwMeta } from "/opt/build/repo/pages/box-office/[performanceId]/scan.vue?macro=true";
import { default as indexQ8hyZg7FV3Meta } from "/opt/build/repo/pages/box-office/[performanceId]/sell/index.vue?macro=true";
import { default as payjjOMhyHAQ9Meta } from "/opt/build/repo/pages/box-office/[performanceId]/sell/pay.vue?macro=true";
import { default as sellDoLdCF3VBkMeta } from "/opt/build/repo/pages/box-office/[performanceId]/sell.vue?macro=true";
import { default as _91performanceId_93w7KMX6ESD3Meta } from "/opt/build/repo/pages/box-office/[performanceId].vue?macro=true";
import { default as configKhuRkhJtRyMeta } from "/opt/build/repo/pages/box-office/config.vue?macro=true";
import { default as indexzipTFOO74XMeta } from "/opt/build/repo/pages/box-office/index.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as indexF687YgCQ5cMeta } from "/opt/build/repo/pages/login/activate/[token]/index.vue?macro=true";
import { default as indexwKbr8wa5b6Meta } from "/opt/build/repo/pages/login/forgot/[token]/index.vue?macro=true";
import { default as indexieVCIvjX36Meta } from "/opt/build/repo/pages/login/forgot/index.vue?macro=true";
import { default as index9WruYoq02MMeta } from "/opt/build/repo/pages/login/index.vue?macro=true";
import { default as privacyjCuzG6sq6pMeta } from "/opt/build/repo/pages/privacy.vue?macro=true";
import { default as indexMh67x8SWwOMeta } from "/opt/build/repo/pages/production/[slug]/book/[performanceId]/index.vue?macro=true";
import { default as overviewNA0H0c425AMeta } from "/opt/build/repo/pages/production/[slug]/book/[performanceId]/overview.vue?macro=true";
import { default as payVhfOwRF5jPMeta } from "/opt/build/repo/pages/production/[slug]/book/[performanceId]/pay.vue?macro=true";
import { default as ticketsHLNE55WwInMeta } from "/opt/build/repo/pages/production/[slug]/book/[performanceId]/tickets.vue?macro=true";
import { default as warningsMuMg4fteUFMeta } from "/opt/build/repo/pages/production/[slug]/book/[performanceId]/warnings.vue?macro=true";
import { default as indexgLNORB7qEjMeta } from "/opt/build/repo/pages/production/[slug]/book/index.vue?macro=true";
import { default as bookWtFKMtGvX1Meta } from "/opt/build/repo/pages/production/[slug]/book.vue?macro=true";
import { default as indexLN6hzgehzMMeta } from "/opt/build/repo/pages/production/[slug]/index.vue?macro=true";
import { default as productions7Z5P4QhOnPMeta } from "/opt/build/repo/pages/productions.vue?macro=true";
import { default as indexM2ZY58XF7dMeta } from "/opt/build/repo/pages/publicity-screen/[venueSlugs]/index.vue?macro=true";
import { default as signupQ6T3vUFXXDMeta } from "/opt/build/repo/pages/signup.vue?macro=true";
import { default as societiesHRT7zSIftHMeta } from "/opt/build/repo/pages/societies.vue?macro=true";
import { default as indexPjpmHDxOaLMeta } from "/opt/build/repo/pages/society/[slug]/index.vue?macro=true";
import { default as terms9FLT7o4CI4Meta } from "/opt/build/repo/pages/terms.vue?macro=true";
import { default as indexuGXVpVL7Z2Meta } from "/opt/build/repo/pages/user/booking/[reference]/index.vue?macro=true";
import { default as tickets2j9PlMCEx7Meta } from "/opt/build/repo/pages/user/booking/[reference]/tickets.vue?macro=true";
import { default as indexgRS95bcrelMeta } from "/opt/build/repo/pages/user/email-verify/[token]/index.vue?macro=true";
import { default as indexuu6yzSj8DAMeta } from "/opt/build/repo/pages/user/index.vue?macro=true";
import { default as indexgMrurowOphMeta } from "/opt/build/repo/pages/venue/[slug]/index.vue?macro=true";
export default [
  {
    name: administrationrIxZaV94DjMeta?.name,
    path: "/administration",
    meta: administrationrIxZaV94DjMeta || {},
    component: () => import("/opt/build/repo/pages/administration.vue"),
    children: [
  {
    name: "administration-finance-reports",
    path: "finance-reports",
    meta: finance_45reportss1JL7sVqXBMeta || {},
    component: () => import("/opt/build/repo/pages/administration/finance-reports.vue")
  },
  {
    name: "administration",
    path: "",
    component: () => import("/opt/build/repo/pages/administration/index.vue")
  },
  {
    name: "administration-productions-productionSlug-bookings-bookingReference",
    path: "productions/:productionSlug()/bookings/:bookingReference()",
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/[bookingReference]/index.vue")
  },
  {
    name: "administration-productions-productionSlug-bookings-create-performanceId",
    path: "productions/:productionSlug()/bookings/create/:performanceId()",
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/create/[performanceId]/index.vue")
  },
  {
    name: "administration-productions-productionSlug-bookings-create",
    path: "productions/:productionSlug()/bookings/create",
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/create/index.vue")
  },
  {
    name: "administration-productions-productionSlug-bookings",
    path: "productions/:productionSlug()/bookings",
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/bookings/index.vue")
  },
  {
    name: "administration-productions-productionSlug-edit",
    path: "productions/:productionSlug()/edit",
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/edit.vue")
  },
  {
    name: "administration-productions-productionSlug",
    path: "productions/:productionSlug()",
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/index.vue")
  },
  {
    name: "administration-productions-productionSlug-performances-performanceId-edit",
    path: "productions/:productionSlug()/performances/:performanceId()/edit",
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/performances/[performanceId]/edit.vue")
  },
  {
    name: "administration-productions-productionSlug-performances-performanceId",
    path: "productions/:productionSlug()/performances/:performanceId()",
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/performances/[performanceId]/index.vue")
  },
  {
    name: "administration-productions-productionSlug-performances-create",
    path: "productions/:productionSlug()/performances/create",
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/performances/create.vue")
  },
  {
    name: "administration-productions-productionSlug-permissions",
    path: "productions/:productionSlug()/permissions",
    component: () => import("/opt/build/repo/pages/administration/productions/[productionSlug]/permissions.vue")
  },
  {
    name: "administration-productions-create",
    path: "productions/create",
    component: () => import("/opt/build/repo/pages/administration/productions/create.vue")
  },
  {
    name: "administration-productions",
    path: "productions",
    component: () => import("/opt/build/repo/pages/administration/productions/index.vue")
  },
  {
    name: "administration-tools-ticket-lookup",
    path: "tools/ticket-lookup",
    component: () => import("/opt/build/repo/pages/administration/tools/ticket-lookup.vue")
  }
]
  },
  {
    name: _91performanceId_93w7KMX6ESD3Meta?.name,
    path: "/box-office/:performanceId()",
    meta: _91performanceId_93w7KMX6ESD3Meta || {},
    component: () => import("/opt/build/repo/pages/box-office/[performanceId].vue"),
    children: [
  {
    name: "box-office-performanceId",
    path: "",
    component: () => import("/opt/build/repo/pages/box-office/[performanceId]/index.vue")
  },
  {
    name: "box-office-performanceId-scan",
    path: "scan",
    component: () => import("/opt/build/repo/pages/box-office/[performanceId]/scan.vue")
  },
  {
    name: sellDoLdCF3VBkMeta?.name,
    path: "sell",
    component: () => import("/opt/build/repo/pages/box-office/[performanceId]/sell.vue"),
    children: [
  {
    name: "box-office-performanceId-sell",
    path: "",
    component: () => import("/opt/build/repo/pages/box-office/[performanceId]/sell/index.vue")
  },
  {
    name: "box-office-performanceId-sell-pay",
    path: "pay",
    component: () => import("/opt/build/repo/pages/box-office/[performanceId]/sell/pay.vue")
  }
]
  }
]
  },
  {
    name: "box-office-config",
    path: "/box-office/config",
    meta: configKhuRkhJtRyMeta || {},
    component: () => import("/opt/build/repo/pages/box-office/config.vue")
  },
  {
    name: "box-office",
    path: "/box-office",
    meta: indexzipTFOO74XMeta || {},
    component: () => import("/opt/build/repo/pages/box-office/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "login-activate-token",
    path: "/login/activate/:token()",
    meta: indexF687YgCQ5cMeta || {},
    component: () => import("/opt/build/repo/pages/login/activate/[token]/index.vue")
  },
  {
    name: "login-forgot-token",
    path: "/login/forgot/:token()",
    meta: indexwKbr8wa5b6Meta || {},
    component: () => import("/opt/build/repo/pages/login/forgot/[token]/index.vue")
  },
  {
    name: "login-forgot",
    path: "/login/forgot",
    meta: indexieVCIvjX36Meta || {},
    component: () => import("/opt/build/repo/pages/login/forgot/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: index9WruYoq02MMeta || {},
    component: () => import("/opt/build/repo/pages/login/index.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/opt/build/repo/pages/privacy.vue")
  },
  {
    name: bookWtFKMtGvX1Meta?.name,
    path: "/production/:slug()/book",
    meta: bookWtFKMtGvX1Meta || {},
    component: () => import("/opt/build/repo/pages/production/[slug]/book.vue"),
    children: [
  {
    name: "production-slug-book-performanceId",
    path: ":performanceId()",
    component: () => import("/opt/build/repo/pages/production/[slug]/book/[performanceId]/index.vue")
  },
  {
    name: "production-slug-book-performanceId-overview",
    path: ":performanceId()/overview",
    component: () => import("/opt/build/repo/pages/production/[slug]/book/[performanceId]/overview.vue")
  },
  {
    name: "production-slug-book-performanceId-pay",
    path: ":performanceId()/pay",
    component: () => import("/opt/build/repo/pages/production/[slug]/book/[performanceId]/pay.vue")
  },
  {
    name: "production-slug-book-performanceId-tickets",
    path: ":performanceId()/tickets",
    component: () => import("/opt/build/repo/pages/production/[slug]/book/[performanceId]/tickets.vue")
  },
  {
    name: "production-slug-book-performanceId-warnings",
    path: ":performanceId()/warnings",
    component: () => import("/opt/build/repo/pages/production/[slug]/book/[performanceId]/warnings.vue")
  },
  {
    name: "production-slug-book",
    path: "",
    component: () => import("/opt/build/repo/pages/production/[slug]/book/index.vue")
  }
]
  },
  {
    name: "production-slug",
    path: "/production/:slug()",
    component: () => import("/opt/build/repo/pages/production/[slug]/index.vue")
  },
  {
    name: "productions",
    path: "/productions",
    component: () => import("/opt/build/repo/pages/productions.vue")
  },
  {
    name: "publicity-screen-venueSlugs",
    path: "/publicity-screen/:venueSlugs()",
    meta: indexM2ZY58XF7dMeta || {},
    component: () => import("/opt/build/repo/pages/publicity-screen/[venueSlugs]/index.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupQ6T3vUFXXDMeta || {},
    component: () => import("/opt/build/repo/pages/signup.vue")
  },
  {
    name: "societies",
    path: "/societies",
    component: () => import("/opt/build/repo/pages/societies.vue")
  },
  {
    name: "society-slug",
    path: "/society/:slug()",
    component: () => import("/opt/build/repo/pages/society/[slug]/index.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/opt/build/repo/pages/terms.vue")
  },
  {
    name: "user-booking-reference",
    path: "/user/booking/:reference()",
    meta: indexuGXVpVL7Z2Meta || {},
    component: () => import("/opt/build/repo/pages/user/booking/[reference]/index.vue")
  },
  {
    name: "user-booking-reference-tickets",
    path: "/user/booking/:reference()/tickets",
    meta: tickets2j9PlMCEx7Meta || {},
    component: () => import("/opt/build/repo/pages/user/booking/[reference]/tickets.vue")
  },
  {
    name: "user-email-verify-token",
    path: "/user/email-verify/:token()",
    meta: indexgRS95bcrelMeta || {},
    component: () => import("/opt/build/repo/pages/user/email-verify/[token]/index.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: indexuu6yzSj8DAMeta || {},
    component: () => import("/opt/build/repo/pages/user/index.vue")
  },
  {
    name: "venue-slug",
    path: "/venue/:slug()",
    component: () => import("/opt/build/repo/pages/venue/[slug]/index.vue")
  }
]