export default [
  "/opt/build/repo/components/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/opt/build/repo/components/global/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/opt/build/repo/components/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/opt/build/repo/layouts/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/opt/build/repo/pages/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/opt/build/repo/plugins/**/*.{js,ts,mjs}",
  "/opt/build/repo/composables/**/*.{js,ts,mjs}",
  "/opt/build/repo/utils/**/*.{js,ts,mjs}",
  "/opt/build/repo/{A,a}pp.{vue,js,jsx,mjs,ts,tsx}",
  "/opt/build/repo/{E,e}rror.{vue,js,jsx,mjs,ts,tsx}",
  "/opt/build/repo/app.config.{js,ts,mjs}"
]