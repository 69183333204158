import validate from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import lockdown_45mode_45global from "/opt/build/repo/middleware/lockdown-mode.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  lockdown_45mode_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/opt/build/repo/middleware/admin.ts"),
  authed: () => import("/opt/build/repo/middleware/authed.ts"),
  "can-boxoffice": () => import("/opt/build/repo/middleware/can-boxoffice.ts"),
  finance: () => import("/opt/build/repo/middleware/finance.ts"),
  "not-authed": () => import("/opt/build/repo/middleware/not-authed.ts")
}